.user-accordion-base {
  border: 1px solid #e3e3e3;
  background-color: #fff;
  .accordion-card {
    > .item {
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > .left {
        width: 50%;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        align-items: center;
        > .card-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          > .arrow {
            display: flex;
            width: 40px;
            justify-content: flex-start;
            svg {
              font-size: 16px !important;
              color: #000 !important;
            }
          }
        }
        .type-caret {
          font-size: 12px;
          padding: 1px 2px;
          &.USER {
            background-color: #dededf;
          }
          &.CLIENT {
            background-color: #f0d8f0;
          }
          &.PUBLISHER {
            background-color: #afd8de;
          }
          &.PROPERTY {
            background-color: #bedeaf;
          }
        }

        > .action {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      > .right {
        display: grid;
        grid-template-columns: 160px 160px 160px 160px;
        align-items: center;
        border-left: 1px solid #e3e3e3;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .option {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          small {
            font-size: 12px;
          }
          span {
            font-size: 18px;
            margin-top: 6px;
          }
        }
      }
    }
    .children {
      //>.accordion-card{
      //  >.item{
      //    >.left{
      //      > .card-info{
      //        padding-left:40px !important;
      //      }
      //    }
      //  }
      //}
    }
  }
}
