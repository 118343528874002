.check-mark {
  border: 1px solid #ccc;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  svg {
    display: none;
  }

  &.selected {
    border: 1px solid var(--primary-color);

    svg {
      display: block;
      line-height: 0;
      color: var(--primary-color);
    }
  }
}

.sortableHolder {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: #000 !important;

  &.passive {
    color: #ccc !important;
  }
}

.explorer-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    box-sizing: border-box;
    > .search {
      width: 50%;
    }
    > .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .table-head-button {
        border: 1px solid #ccc;
        background: #efefef;
        padding: 6px !important;
        &.save {
          background: #1bb73f;
          color: #fff;
          svg {
            color: #fff;
          }
        }
        &.open {
          background: #fff;
          svg {
            color: #000;
          }
        }
        svg {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .table-base {
    width: 100%;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    overflow-y: scroll;
    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      thead {
        background: #9d9d9d;
        border-bottom: 1px solid #ccc;

        tr {
          border: none;

          td {
            font-weight: 500;
            font-size: 12px;
            height: 30px;
            color: #fff;
            padding: 16px 8px;

            svg {
              color: #fff;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #e3e3e3;
          background: #fff;
          &:nth-child(odd) {
            background: #f9f9f9;
          }
          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background: #f9f9f9;
          }

          &.passive {
            color: #9a9a9a !important;
          }

          td {
            padding: 18px 8px;
            font-weight: 400;
            font-size: 12px;
            position: relative;
            height: 40px;

            &.pin {
              background: #deebff;
            }

            .screenshot-btn {
              border: 1px solid #e3e3e3;
              padding: 4px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              width: 50px;
              border-radius: 10px;
              background: #fff;
              cursor: pointer;
              color: #7f37be;

              &:hover {
                background: var(--link-color);
                color: #fff;
                border: 1px solid var(--link-color);
              }

              svg {
                margin-right: 4px;
              }
            }

            .ad-detail-btn {
              font-size: 12px;
              border: 1px solid #e3e3e3;
              padding: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              background: #fff;
              cursor: pointer;
              color: #7f37be;
              width: auto;

              &:hover {
                background: var(--link-color);
                color: #fff;
                border: 1px solid var(--link-color);
              }

              svg {
                margin-right: 4px;
              }
            }

            .reactivate-btn {
              font-size: 12px;
              border: 1px solid var(--primary-color);
              padding: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              background: var(--primary-color);
              cursor: pointer;
              color: #fff;
              width: auto;

              &:hover {
                background: var(--link-color);
                color: #fff;
                border: 1px solid var(--link-color);
              }

              svg {
                margin-right: 4px;
              }
            }

            .free-trial-btn {
              font-size: 10px;
              border: 1px solid lightgrey;
              padding: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              background: transparent;
              cursor: pointer;
              color: #9a9a9a;
              width: auto;

              &:hover {
              }

              svg {
                margin-right: 4px;
              }
            }

            .edit-btn {
              font-size: 12px;
              border: 1px solid #e3e3e3;
              padding: 4px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              border-radius: 10px;
              background: #fff;
              cursor: pointer;
              color: #7f37be;
              width: 50px;

              &:hover {
                background: var(--link-color);
                color: #fff;
                border: 1px solid var(--link-color);
              }

              svg {
                margin-right: 4px;
              }

              &.delete-btn {
                width: auto;

                svg {
                  margin: 0;
                }
              }
            }

            .list-svg {
              border: 1px solid #e3e3e3;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #fff;

              svg {
                color: var(--link-color);
              }
            }

            a {
              color: var(--link-color);
            }

            svg {
              font-size: 14px;

              &.active {
                color: var(--green);
              }

              &.pending {
                color: var(--yellow);
              }

              &.inactive {
                color: #535353;
              }

              &.free_trial {
                color: var(--green);
              }

              &.cancelled {
                color: var(--alert-color);
              }
            }
          }
        }
      }
    }
  }
  .table-footer {
    width: 100%;
    border-radius: 0 0 10px 10px;
    border: 1px solid #e3e3e3;
    padding: 10px;
    background: #e3e3e3;
    box-sizing: border-box;
  }
}
.list-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;

  thead {
    background: transparent;

    tr {
      border-bottom: 1px solid #e3e3e3;

      td {
        padding: 8px;
        font-weight: 500;
        font-size: 12px;
        height: 30px;
      }
    }
  }

  tbody {
    tr:nth-child(odd) {
      background: #fcf8ff;
    }

    tr:nth-child(even) {
      background: #fff;
    }

    tr {
      border-bottom: 1px solid #e3e3e3;

      &:hover {
        background: #f9f9f9;
      }

      &.passive {
        color: #9a9a9a !important;
      }

      td {
        padding: 0 8px 0 8px;
        font-weight: 400;
        font-size: 12px;
        position: relative;
        height: 40px;

        .screenshot-btn {
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 50px;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;

          &:hover {
            background: var(--link-color);
            color: #fff;
            border: 1px solid var(--link-color);
          }

          svg {
            margin-right: 4px;
          }
        }

        .ad-detail-btn {
          font-size: 12px;
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;
          width: auto;

          &:hover {
            background: var(--link-color);
            color: #fff;
            border: 1px solid var(--link-color);
          }

          svg {
            margin-right: 4px;
          }
        }

        .reactivate-btn {
          font-size: 12px;
          border: 1px solid var(--primary-color);
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: var(--primary-color);
          cursor: pointer;
          color: #fff;
          width: auto;

          &:hover {
            background: var(--link-color);
            color: #fff;
            border: 1px solid var(--link-color);
          }

          svg {
            margin-right: 4px;
          }
        }

        .free-trial-btn {
          font-size: 10px;
          border: 1px solid lightgrey;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          background: transparent;
          cursor: pointer;
          color: #9a9a9a;
          width: auto;

          &:hover {
          }

          svg {
            margin-right: 4px;
          }
        }

        .edit-btn {
          font-size: 12px;
          border: 1px solid #e3e3e3;
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 10px;
          background: #fff;
          cursor: pointer;
          color: #7f37be;
          width: 50px;

          &:hover {
            background: var(--link-color);
            color: #fff;
            border: 1px solid var(--link-color);
          }

          svg {
            margin-right: 4px;
          }

          &.delete-btn {
            width: auto;

            svg {
              margin: 0;
            }
          }
        }

        .list-svg {
          border: 1px solid #e3e3e3;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;

          svg {
            color: var(--link-color);
          }
        }

        a {
          color: #521289;
        }

        svg {
          font-size: 14px;

          &.active {
            color: var(--green);
          }

          &.pending {
            color: var(--yellow);
          }

          &.inactive {
            color: #535353;
          }

          &.free_trial {
            color: var(--green);
          }

          &.cancelled {
            color: var(--alert-color);
          }
        }
      }
    }
  }

  &.large {
    td {
      padding: 0 8px 0 8px;
      font-weight: 400;
      font-size: 12px;
      position: relative;
      height: 40px;

      button {
        font-size: 14px;
      }
    }
  }

  &.payment-list {
    width: 100%;

    .payment-method {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      label {
        select {
          border: 1px solid #ccc;
          padding: 10px;
          background: #fff;
        }

        svg {
          right: 10px !important;
        }
      }
    }
  }

  &.type-card {
    tbody {
      tr {
        margin: 4px 4px !important;
      }
    }
  }
}

.table-selector {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;

  .dropdown-menu {
    position: absolute;
    background: #fff;
    top: 30px;
    border-radius: 8px;
    min-width: 200px;
    z-index: 40;

    > .dropdown-title {
      padding: 10px;
      border-bottom: 1px solid #efefef;
      font-size: 14px;
      font-weight: 400;
    }
    > .dropdown-body {
    }
    > .dropdown-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.table-type-transition {
  display: grid;
  width: 100%;
  grid-template-columns: 40% 20% 40%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #efefef;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 !important;
  box-sizing: border-box;
  span {
    box-sizing: border-box;
    &:first-child {
      color: #797979 !important;
    }
    &:last-child {
      color: #0d6220 !important;
    }
  }
  > .icon {
    display: flex;
    flex-direction: column;
    svg {
      &:first-child {
        color: red;
      }
      &:last-child {
        position: absolute;
        transform: rotate(180deg);
        margin-top: 6px;
        margin-left: 6px;
        color: var(--green);
      }
    }
  }
}
