:root {
  --primary-color: rgb(48, 118, 28);
  // TODO
  //--secondary-color: rgb(48, 118, 28);
  //--success-color: rgb(48, 118, 28);
  //--info-color: rgb(48, 118, 28);
  //--warning-color: rgb(48, 118, 28);
  //--danger-color: rgb(48, 118, 28);
  //--light-color: rgb(48, 118, 28);
  //--dark-color: rgb(48, 118, 28);
  --gray-10: rgba(0, 0, 0, 0.1);
  --gray-20: rgba(0, 0, 0, 0.2);
  --gray-40: rgba(0, 0, 0, 0.4);
  --gray-50: rgba(0, 0, 0, 0.5);
  --gray-70: rgba(0, 0, 0, 0.7);
  --link-color: #1640aa;
  --alert-color: #ff2c2c;
  --yellow: #fed326;
  --green: #0d6220;
}

#layout > #project > #page {
  padding-bottom: 50px;
  max-height: 100vh;
}
//
//.auth-loader > div,
//.evet-button > .logo,
//#layout > #aside > .head > .logo {
//  mask-image: url('../images/logo.svg');
//}

//TODO <p> marginlerini lib'e almak lazim
p {
  margin: 0;
}

.pagination-info {
  span {
    padding: 4px;
    display: inline-block;
    font-size: 12px;
  }
}

.animation {
  transition: all 400ms ease-out;
}
.show {
  display: flex !important;
}
.hide {
  display: none !important;
}
.container {
  width: 90%;
  > .list-page {
    width: 100%;
    display: grid;
    grid-template-columns: 250px calc(100% - 250px);
    grid-column-gap: 40px;
    .side-filter {
      width: 100%;
    }
    .results {
    }
  }
}
.section-title {
  h3 {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .filter {
  }
}
.react-confirm-alert-button-group {
  button {
    padding: 12px !important;
    background: var(--primary-color) !important;
    cursor: pointer !important;
    font-size: 14px !important;
    outline: none !important;
    transition: all 0.4s !important;
    border-radius: 4px !important;
    width: auto !important;
    margin-right: 6px !important;
    &:nth-child(1) {
      background: #fff !important;
      color: var(--gray-50) !important;
      border: 1px solid var(--gray-50) !important;
    }
  }
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}
.react-confirm-alert-body {
  padding: 10px !important;
}

//.btn-loader {
//  border: 2px solid #fff;
//  border-radius: 50%;
//  border-top: 2px solid var(--primary-color);
//  border-bottom: 2px solid var(--primary-color);
//  width: 12px;
//  height: 12px;
//  margin-right: 10px;
//  animation: spin 2s linear infinite;
//  @keyframes spin {
//    0% {
//      transform: rotate(0deg);
//    }
//    100% {
//      transform: rotate(360deg);
//    }
//  }
//}

//.loader {
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  &.inline {
//    position: relative !important;
//    top: auto !important;
//    left: auto !important;
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    #round1 {
//      margin: 0 0 0 0;
//    }
//  }
//  #round1 {
//    display: block;
//    height: 50px;
//    width: 50px;
//    margin: -25px 0 0 -25px;
//    -webkit-animation: round1-rotate 2s infinite linear;
//    animation: round1-rotate 2s infinite linear;
//  }
//
//  #round1 span {
//    width: 30px;
//    height: 30px;
//    display: block;
//    position: absolute;
//    background-color: var(--primary-color);
//    border-radius: 50%;
//    -webkit-animation: round1-bounce 2s infinite ease-in-out;
//    animation: round1-bounce 2s infinite ease-in-out;
//  }
//
//  #round1 span:nth-child(2) {
//    top: auto;
//    bottom: 0;
//    -webkit-animation-delay: -1s;
//    animation-delay: -1s;
//  }
//}

@keyframes round1-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes round1-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.relative {
}

.modal-form {
  @keyframes smooth-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  &.open {
    display: flex;
    height: 100%;
    animation: smooth-show 1s;
  }

  &.close {
    display: none;
    opacity: 0;
  }

  display: flex;
  align-items: center !important;
  justify-content: center;
  position: fixed;
  width: 100%;
  overflow-x: auto;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 60;

  &-container {
    &.website-container {
      min-width: 860px;
      max-width: 600px;
    }
    &.payment-container {
      min-width: 700px;
      max-width: 600px;
    }
    &.user-container {
      min-width: 700px;
      max-width: 600px;
    }

    box-sizing: border-box;
    background: #fff;
    .modal-form-title {
      border-bottom: 1px solid #ccc !important;
      padding: 10px;
      background: #f9f9f9;
      h3 {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

//.opened-menu {
//  background: $border-purple;
//}

.check-mark {
  border: 1px solid #ccc;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  svg {
    display: none;
  }
  &.selected {
    border: 1px solid var(--primary-color);
    svg {
      display: block;
      line-height: 0;
      color: var(--primary-color);
    }
  }
}
