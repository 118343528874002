.grand-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .quick-tip {
    svg {
      display: none;
    }
  }
  .table-head {
    display: grid;
    grid-template-columns: 25fr 25fr 25fr 25fr;
    background-color: #f7f6f7;
    border-bottom: 1px solid #e3e3e3;
    > span {
      font-size: 12px;
      font-weight: 500;
      padding: 6px 10px;
    }
  }
  .table-body {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #e3e3e3;
      &:last-child {
        border-bottom: none;
      }
      &:nth-child(1) {
        background-color: #fdfce8;
      }
      &:nth-child(2) {
        background-color: #f0f7ed;
      }
      &:nth-child(3) {
        background-color: #efefef;
      }
      &:nth-child(4) {
        background-color: #e9eff8;
      }
      &:nth-child(5) {
        background-color: #f9eeed;
      }
      &:nth-child(6) {
        background-color: #f5eefb;
      }
      .fields,
      .sub-fields {
        display: grid;
        grid-template-columns: 25fr 25fr 25fr 25fr;
        > div.cell {
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background: transparent;
          border-left: 1px solid #e3e3e3;
          font-size: 14px;
          > span {
            //background: #fff;
            //padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //cursor: pointer;
          }
          a {
            color: #000;
            cursor: pointer;
            position: relative;
            small {
              margin-left: 10px;
            }

            svg {
              font-size: 12px;
              cursor: pointer;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .sub-fields {
        border-top: 1px solid #e3e3e3;
        > span {
          //background: #f9f9f9;
          justify-content: flex-start;
          svg {
            //padding-right: 10px;
            font-size: 22px;
          }
        }
        > a {
          background: inherit;
        }
      }
    }
  }
}
