.cancel-modal {
  textarea {
    width: 100%;
    height: 70px;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;
    &:focus {
      outline: none;
    }
  }
}
.add-advertisers {
  width: 700px;
  h2 {
    font-size: 12px;
    font-weight: 600;
  }
  .create-advertiser {
    background: #f9f9f9;
  }
}
.advertiser-id {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  img {
    width: 120px;
  }
  .detail {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
