.client-library-table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  background: #fff;
  > .data {
    display: grid;
    grid-template-columns: auto 170px 100px 200px 140px 100px;
    background: #fff;
    border-bottom: 1px solid #efefef;
    &.active {
      background: #f9f9f9;
      margin-top: 10px;
      border: 1px solid #e3e3e3;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-left: 1px solid #efefef;
      padding: 10px;
      &.active {
        background: #fff;
      }
      &:first-child {
        border: none;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
      }

      p,
      a {
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }
  &.opened {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    .data {
      background: #f9f9f9 !important;
    }
  }
  > .dropdown {
    background: #f9f9f9;
    width: 100% !important;
    display: flex;
  }
}
.publisher-library-table {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  display: grid;
  grid-template-columns: auto 120px 120px 120px 130px;
  > .item {
    padding: 20px 10px;
    border-right: 1px solid #e3e3e3;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:last-child {
      border-right: none;
    }
    span {
      font-size: 12px;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}
