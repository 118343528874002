.commission-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 6px;

  &.opened {
    margin-top: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
  }

  .card-base {
    background: #fff;
    border: 1px solid #efefef;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 90px;

    .title {
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 100px;
      }

      .detail {
        padding-left: 20px;
        display: flex;
        flex-direction: column;

        span {
          font-size: 12px;
          padding-bottom: 4px !important;
          color: #7a7a7a;

          &.more-less {
            color: #000;
            font-size: 16px;
          }
        }

        p {
          font-size: 16px;
        }

        a {
          font-size: 12px;
          color: var(--link-color);
          padding-top: 4px !important;

          .more-less {
            font-size: 12px !important;
            color: var(--link-color) !important;
          }
        }
      }
    }

    .data {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      .item {
        border-left: 1px solid #efefef;
        padding: 10px 30px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 120px;
        max-width: 160px;
        span {
          font-size: 12px;
          font-weight: 400;
        }

        p {
          font-size: 12px;
          padding-top: 4px;
        }
      }

      .action {
        border-left: 1px solid #efefef;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 70px;
      }
    }
  }

  .dropdown {
    background: #fff;
    padding: 10px;
    border: 1px solid #efefef;
    border-top: none;

    .explanation {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      box-sizing: border-box;
      background: #f9f9f9;
      border: 1px solid #efefef;
      border-radius: 6px;

      h2 {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .info {
        .info-table {
          .column {
            display: flex;
            margin-bottom: 8px;

            span {
              font-size: 12px;
              width: 150px;
            }

            p {
              font-size: 12px;
            }

            a {
              font-size: 12px;
            }
          }
        }
      }

      .description {
        .scroll-area {
          height: 100px;
          overflow-x: auto;
          padding-right: 10px;

          p {
            font-size: 12px;
            line-height: 20px !important;
          }
        }
      }
    }
  }

  &.in-modal {
    .card-base {
      .title {
        img {
          width: 70px !important;
        }
      }

      .data {
        .item {
          width: 70px;
        }
      }
    }
  }
}

.exclusive-caret {
  position: absolute;
  border-radius: 4px;
  background: #c73d3d;
  color: #fff;
  right: 10px;
  padding: 2px 6px;
  top: 11px;
}

.commission-log-table {
  min-height: auto;
  max-height: 260px;
  overflow-x: auto;
}
