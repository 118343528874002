.reporting-filter {
  padding: 10px 0;

  .section {
    padding-bottom: 20px;
    span {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      color: #777777;
    }
  }
}

.reporting-sub-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .reporting-menu-link {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e3e3e3;
    padding: 16px 10px;
    border-right: 1px solid #ccc;

    &:first-child {
      border-radius: 6px 0 0 0;
    }

    &:last-child {
      border-radius: 0 6px 0 0;
      border-right: none;
    }

    svg {
      font-size: 14px;
      margin-right: 10px;
      color: #8a8a8a;
    }

    span {
      font-size: 16px;
      font-weight: 300;
      color: #8a8a8a;
    }

    &.active {
      background: #fff;
      border: 1px solid #e3e3e3;
      border-bottom: none;

      svg,
      span {
        color: #000;
      }
    }
  }
}

.reporting-base {
  .reporting-summary {
    background: #fff;

    .reporting-summary-head {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      border-left: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
    }

    .reporting-chart-base {
      border: 1px solid #efefef;
      overflow: hidden;
      transition: all 600ms;
      border-radius: 0 0 10px 10px;

      &.true {
        height: 244px;
      }

      &.false {
        height: 0;
      }

      .react-tabs {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;

        > ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
          width: 22%;
          border-right: 1px solid #efefef;

          li {
            display: flex;
            justify-content: space-between;
            padding: 17px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #efefef;
            &:hover {
              cursor: pointer;
            }
            &:focus {
              outline: none;
            }
            p {
              font-size: 12px;
            }
            span {
              font-size: 14px;
            }
          }
        }

        .react-tabs__tab--selected {
          background: #eaf1ec;
          border-right: 4px solid var(--primary-color);
        }

        .react-tabs__tab-panel {
          width: 78%;
          padding: 20px;

          .recharts-wrapper {
            width: 100%;
          }
        }

        .recharts-legend-wrapper {
        }
      }
    }
  }
}
