.offer-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 16px;
  background: #fff;
  border: 1px solid #efefef;

  .card-head {
    border-bottom: 1px solid #efefef;
    .coupon-code {
      display: flex;
      align-items: center;
      padding: 10px;
    }
    .offer-status {
      display: flex;
      justify-content: flex-end;
      position: relative;
      cursor: pointer;
      padding: 10px 20px;
      &:hover {
        .status-info {
          display: flex;
        }
      }
      .status-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: block;
          margin-left: 2px;
          height: 10px;
          width: 6px;
          background: #e3e3e3;
        }
      }
      .status-info {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        background: #fff;
        top: 8px;
        border-radius: 10px;
        width: 200px;
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 6px 10px;
          border-bottom: 1px solid #efefef;
          width: 100%;
          &:last-child {
            border: none;
          }
          p {
            display: block;
            font-size: 12px !important;
            color: #9a9a9a;
          }
          svg {
            font-size: 8px !important;
            margin-right: 10px;
            color: #9a9a9a;
          }
        }
      }

      &.level-1 {
        .status-bar {
          span {
            &:nth-child(1) {
              background: var(--green) !important;
            }
          }
        }
        .status-info {
          .item {
            &:nth-child(1) {
              svg {
                color: var(--green);
              }
              p {
                color: #000;
              }
            }
          }
        }
      }
      &.level-2 {
        .status-bar {
          span {
            &:nth-child(1) {
              background: var(--green);
            }
            &:nth-child(2) {
              background: var(--green);
            }
          }
        }
        .status-info {
          .item {
            &:nth-child(1) {
              svg {
                color: var(--green);
              }
              p {
                color: #000;
              }
            }
            &:nth-child(2) {
              svg {
                color: var(--green);
              }
              p {
                color: #000;
              }
            }
          }
        }
      }
      &.level-3 {
        .status-bar {
          span {
            &:nth-child(1) {
              background: var(--green);
            }
            &:nth-child(2) {
              background: var(--green);
            }
            &:nth-child(3) {
              background: var(--green);
            }
          }
        }
        .status-info {
          .item {
            &:nth-child(1) {
              svg {
                color: var(--green);
              }
              p {
                color: #000;
              }
            }
            &:nth-child(2) {
              svg {
                color: var(--green);
              }
              p {
                color: #000;
              }
            }
            &:nth-child(3) {
              svg {
                color: var(--green);
              }
              p {
                color: #000;
              }
            }
          }
        }
      }
    }
  }

  .card-base {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    .tags {
      span {
        padding: 4px 10px;
        border-radius: 30px;
        border: 1px solid #ccc;
        background: #f9f9f9;
        font-size: 12px;
      }
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .media {
        padding: 10px;
        box-sizing: border-box;
        width: 90px;
        height: 100%;
        border-right: 1px solid #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: 80%;
          object-fit: cover;
        }
      }

      .detail {
        padding-left: 20px;

        span {
          font-size: 12px;
        }

        p {
          font-size: 14px;
        }

        a {
          font-size: 12px;
          color: var(--link-color);
          padding-top: 0 !important;
        }

        .offer-date {
          color: #9a9a9a;
        }
      }
    }

    .data {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      .item {
        border-left: 1px solid #efefef;
        padding: 10px 30px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          font-size: 12px;
          font-weight: 300;
        }

        p {
          font-size: 14px;
          padding-top: 4px;
        }
      }

      .action {
        border-left: 1px solid #efefef;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .card-foot {
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    border-top: 1px solid #efefef;

    .detail {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      span {
        padding: 10px 20px;
        border-right: 1px solid #efefef;
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        p {
          margin-left: 6px;
        }
      }
    }
    .offer-date {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        padding: 10px;
        font-size: 12px;
      }
    }
  }

  .dropdown {
    background: #fff;
    padding: 10px;
    border: 1px solid #efefef;
    border-top: none;

    .explanation {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      box-sizing: border-box;
      background: #f9f9f9;
      border: 1px solid #efefef;
      border-radius: 6px;

      h2 {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .info {
        .info-table {
          .column {
            display: flex;
            margin-bottom: 8px;

            span {
              font-size: 12px;
              width: 150px;
            }

            p {
              font-size: 12px;
            }
          }
        }
      }

      .description {
        .scroll-area {
          height: 100px;
          overflow-x: auto;
          padding-right: 10px;

          p {
            font-size: 12px;
            line-height: 20px !important;
          }
        }
      }
    }
  }
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3),
      0 4px 8px 3px rgba(0, 0, 0, 0.15);
  }
}

.offer-modal {
  width: 900px;

  h3 {
    font-size: 12px;
    font-weight: 600;
  }

  .info {
    background: #f9f9f9;
    box-sizing: border-box;

    .info-table {
      .column {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #ccc;

        &:last-child {
          border: none;
        }

        span {
          font-size: 14px;
          width: 150px;
        }

        p {
          font-size: 14px;
        }

        label {
          padding-left: 4px;
          display: flex;
          align-items: stretch;

          input {
            border: 1px solid #ccc;
            border-right: none;
            background: #fff;
            padding: 10px;
          }

          button {
            padding-left: 10px;
            border: none;
            background: var(--green);
            color: #fff;
          }
        }
      }
    }
  }

  .media {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70%;
      object-fit: cover;
    }
  }

  .tab-menu {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;

    li {
      background: #e3e3e3;
      list-style-type: none;
      padding: 10px;
      font-size: 14px;
      cursor: pointer;
      outline: none;
      display: flex;
      align-items: center;
      color: #5d5d5d !important;
      border-right: 1px solid #efefef;

      &:hover {
        background: #45486d;
        color: #fff !important;
      }

      &:last-child {
        border-right: none;
      }

      &.react-tabs__tab--selected {
        background: #45486d;
        color: #fff !important;
      }

      svg {
        margin-right: 10px;
      }
    }

    &.switch-style {
      &.item {
        background: transparent !important;
      }

      li {
        padding: 0;
        background: transparent;
        outline: none;
        cursor: pointer;

        span {
          color: var(--gray70);

          p {
            background: var(--gray70) !important;
          }
        }

        &:nth-child(1) {
          border-radius: 10px 0 0 10px;

          &.react-tabs__tab--selected {
            span {
              color: #000;

              p {
                background: var(--green) !important;
              }
            }
          }
        }

        &:nth-child(2) {
          border-radius: 0 10px 10px 0;

          &.react-tabs__tab--selected {
            span {
              color: #000;

              p {
                background: var(--alert-color) !important;
              }
            }
          }
        }
      }
    }
  }

  .code-base {
    background: #161a42;

    .code {
      color: #fff;
      padding: 20px;
      font-family: 'PT Mono', monospace;
      line-height: 18px;
      word-wrap: break-word;
      overflow-x: auto;
      height: 80px;
      box-sizing: border-box;
    }

    .copy-clipboard {
      width: 100%;
      padding: 10px;
      background: #45486d;
      color: #fff;
      border: none;
      font-size: 14px;

      svg {
        margin-right: 6px;
      }
    }
  }

  .react-tabs__tab-panel--selected {
    box-sizing: border-box;
    font-size: 14px;
  }
}
