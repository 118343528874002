.client-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  background: #fff;

  > .data {
    display: grid;
    grid-template-columns: auto 140px 120px 100px 120px 120px;
    border: 1px solid #efefef;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-left: 1px solid #efefef;
      padding: 10px;
      &.active {
        background: #fff;
      }
      &:first-child {
        border: none;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        color: #747474;
        text-transform: uppercase;
      }

      p,
      a,
      button {
        font-size: 14px;
        margin-top: 4px;
        color: royalblue;
      }
      p {
        color: #000;
      }
    }
  }

  &.opened {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
    .data {
      background: #f9f9f9 !important;
    }
  }

  > .dropdown {
    padding: 0;
    border: 1px solid #efefef;
    border-top: none;
    min-height: 90px;
    max-height: 240px;
    overflow-x: auto;
  }

  &.in-modal {
    .card-base {
      .title {
        img {
          width: 70px !important;
        }
      }

      .data {
        .item {
          width: 70px;
        }
      }
    }
  }
}

.publisher-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 30px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  background-color: #fff;

  > .card-base {
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 6px 0 0 6px;
    overflow: hidden;

    .title {
      padding: 12px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #ccc;

      .logo {
        background: #e3e3e3;
        font-size: 32px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .detail {
        display: flex;
        flex-direction: column;

        span {
          font-size: 12px;
          padding-top: 4px;
          color: #7a7a7a;

          &.more-less {
            color: #000;
            font-size: 16px;
          }
        }

        p {
          font-size: 18px;
        }

        a {
          font-size: 12px;
          color: var(--link-color);
          padding-top: 4px !important;

          .more-less {
            font-size: 12px !important;
            color: var(--link-color) !important;
          }
        }
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      .item {
        padding: 6px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
        box-sizing: border-box;

        > span {
          font-size: 12px;
          font-weight: 500;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          svg {
            margin-right: 8px;
          }
        }

        p {
          font-size: 12px;
        }
      }

      .action {
        position: relative;
        border-left: 1px solid #efefef;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 70px;
      }
    }
  }

  .card-actions {
    border: 1px solid #efefef;
    border-top: none;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .dropdown {
    background: #fff;
    height: 320px;
    padding: 10px;
    overflow-x: auto;

    .explanation {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      box-sizing: border-box;
      background: #f9f9f9;
      border: 1px solid #efefef;
      border-radius: 6px;

      h2 {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .info {
        .info-table {
          .column {
            display: flex;
            padding: 4px 0 4px 0;

            span {
              font-size: 12px;
              width: 150px;
            }

            p {
              font-size: 12px;
            }

            a {
              font-size: 12px;
            }
          }
        }
      }

      .description {
        .scroll-area {
          height: 100px;
          overflow-x: auto;
          padding-right: 10px;

          p {
            font-size: 12px;
            line-height: 20px !important;
          }
        }

        .category {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          p {
            padding: 2px 4px 2px 4px;
            border: 1px solid #ccc;
            margin: 0 4px 4px 0;
            background: #fff;
            border-radius: 2px;
            font-size: 12px;
          }
        }
      }
    }

    table {
      thead {
        tr {
          td {
            height: 54px;
          }
        }
      }

      tbody {
        tr {
          td {
            height: 50px;
          }
        }
      }
    }
  }

  &.in-modal {
    .card-base {
      .title {
        img {
          width: 70px !important;
        }
      }

      .data {
        .item {
          width: 70px;
        }
      }
    }
  }
}

.publisher-add-modal {
  width: 700px;

  &.contact {
    width: 500px !important;
  }
}

.client-id {
  .logo {
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    font-size: 32px;
    font-weight: 400;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #9a9a9a;
  }

  .detail {
    padding-left: 20px;

    p {
      &:nth-child(1) {
        font-size: 12px;
        color: #888;
      }

      &:nth-child(2) {
        margin-top: 4px;
        font-size: 18px;
        font-weight: 300;
        color: #000;
        text-transform: capitalize;
      }

      &:nth-child(3) {
        font-size: 12px;
        color: #888;
        margin-top: 4px;
      }
    }
  }
}

.advertisers-list-modal {
  max-height: 300px;
  overflow-x: auto;
}

.permission-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
  thead {
    tr {
      background-color: #fff;
      border-bottom: 1px solid #e3e3e3;
      td {
        padding: 6px;
        font-size: 14px;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e3e3e3;
      td {
        padding: 8px;
        font-size: 12px;
        text-align: center;
        vertical-align: middle;
        svg {
          color: var(--green);
          font-size: 16px;
        }
        &:first-child {
          background-color: #f9f9f9;
          text-align: left;
        }
      }
    }
  }
}
