.advertiser-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 6px;
  &.opened {
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 11px -1px rgba(0, 0, 0, 0.17);
  }
  .card-base {
    background: #fff;
    border: 1px solid #efefef;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .title {
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 100px;
      }
      .detail {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        span {
          font-size: 12px;
          padding-bottom: 4px !important;
          color: #7a7a7a;
          &.more-less {
            color: #000;
            font-size: 16px;
          }
        }
        p {
          font-size: 16px;
        }
        a {
          font-size: 12px;
          color: var(--link-color);
          padding-top: 4px !important;
          .more-less {
            font-size: 12px !important;
            color: var(--link-color) !important;
          }
        }
      }
    }
    .data {
      display: grid;
      grid-template-columns: 230px 100px 100px 100px auto;
      justify-content: flex-start;
      align-items: stretch;
      .item {
        border-left: 1px solid #efefef;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        span {
          font-size: 12px;
          font-weight: 400;
        }
        p {
          font-size: 12px;
          padding-top: 4px;
        }
      }
      .action {
        border-left: 1px solid #efefef;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  > .dropdown {
    background: #fff;
    padding: 10px;
    border: 1px solid #efefef;
    border-top: none;
    .explanation {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px;
      box-sizing: border-box;
      background: #f9f9f9;
      border: 1px solid #efefef;
      border-radius: 6px;
      h2 {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .info {
        .info-table {
          .column {
            display: flex;
            margin-bottom: 8px;
            span {
              font-size: 12px;
              width: 150px;
            }
            p {
              font-size: 12px;
            }
            a {
              font-size: 12px;
            }
          }
        }
      }
      .description {
        .scroll-area {
          height: 100px;
          overflow-x: auto;
          padding-right: 10px;
          p {
            font-size: 12px;
            line-height: 20px !important;
          }
        }
      }
    }
  }
  &.in-modal {
    .card-base {
      .title {
        img {
          width: 70px !important;
        }
      }
      .data {
        .item {
          width: 70px;
        }
      }
    }
  }
}
.network-modal {
  width: 700px;
  height: 290px;
  h3 {
    font-size: 12px;
    font-weight: 600;
  }
  .info-table {
    .column {
      display: flex;
      margin-bottom: 4px;
      span {
        font-size: 12px;
        width: 150px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .scroll-area {
    height: 96px;
    overflow-x: auto;
    padding-right: 10px;
    box-sizing: border-box;
    &.commission {
      height: 250px;
    }
    &.offers {
      height: 250px;
    }
    p {
      font-size: 12px;
      line-height: 16px !important;
    }
  }
  .tag-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .item {
      padding: 6px;
      margin: 0 8px 8px 0;
      display: flex;
      justify-content: space-between;
      border: 1px solid #ccc;
      border-radius: 6px;
      p {
        font-size: 12px;
      }
      span {
        font-size: 12px;
        padding-left: 20px;
      }
    }
  }
  .tab-menu {
    .title {
      list-style: none !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      padding: 0;
      margin: 0;

      li {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        width: 50%;
        cursor: pointer;
        background: #efefef;
        color: #757575;

        &:focus {
          outline: none;
        }

        &.react-tabs__tab--selected {
          background: none;
          color: #000;
        }
      }
    }
  }
}
.smiler-item {
  background: #f4fff4 !important;
  border-bottom: 1px dashed var(--green) !important;
}
