.transactions-filter-grid {
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  justify-content: space-between;
  align-items: center;
  .item {
    margin: 10px 0;
  }
}
.select-filter-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  input {
    display: none;
  }
}
