.custom-tooltip {
  padding: 0.6em;
  font-size: 0.8em;
  color: #111;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 8px;
  line-height: 1.5em;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  > p {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.3em;
    margin-bottom: 0.3em;
    &:last-child {
      border: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .label {
    span {
      font-weight: bold;
    }
  }
}
