.highest-rated-base {
  width: 100%;

  &.top-brands {
    .item {
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      .logo {
        border-right: 1px solid #e3e3e3;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        padding: 10px;

        img {
          width: 80%;
        }
      }

      .brand {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        border-right: 1px solid #e3e3e3;

        a {
          color: var(--link-color);
          text-decoration: underline;
        }
      }

      .region {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        border-right: 1px solid #e3e3e3;
      }

      .sale {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
      }
    }
  }

  &.top-pages {
    .item {
      border-bottom: 1px solid #e3e3e3;
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      .page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
        border-right: 1px solid #e3e3e3;

        a {
          color: var(--link-color);
          text-decoration: underline;
        }
      }

      .sale {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
      }
    }
  }
}

.network-single-tabs {
  width: 100%;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    li {
      cursor: pointer;
      background: #efefef;
      padding: 20px;

      &:focus {
        outline: none;
      }

      &.react-tabs__tab--selected {
        background: #fff;
      }

      p {
        font-size: 12px;
      }

      span {
        font-size: 18px;
        font-weight: 400;
        padding-top: 6px;
      }
    }
  }
}

.chart-base {
  .chart-summary {
    span {
      font-size: 14px;
    }

    > .count {
      padding: 10px 0;

      p {
        font-size: 32px;
      }
    }
  }
}
